<template>
    <b-overlay :show="loading">
        <form-payment @submit="submit" :payload="penjualan"></form-payment>
    </b-overlay>
</template>
<script>
import FormPayment from './components/Payment.vue'
import {BOverlay} from 'bootstrap-vue'
export default {
    data: () => ({
        penjualan: null,
        loading: false
    }),
    components: {
        FormPayment,
        BOverlay
    },
    methods: {
        async getKonsumenInfo() {
            const konsumen = this.penjualan.konsumen
            if(konsumen) {
                this.$store.commit('penjualan/SET_CURRENT_KONSUMEN', konsumen)
            }
        },
        async submit(data) {
            const payload = {
                id_penjualan: this.$route.params.id,
                metode: data.metode,
                bank: data.bank,
                transfer: data.transfer,
                tunai: data.tunai,
                nominal: data.nominal,
                uang_masuk: data.uang_masuk,
                giro: data.giro,
                no_giro: data.no_giro,
                tgl_giro: data.tgl_giro,
                tgl_bayar: data.tgl_bayar,
                norek: data.norek,
                pemilik: data.pemilik,
                diskon: data.diskon,
                saldo: data.saldo,
                keterangan: data.keterangan,
                tgl_pembayaran: data.tgl_pembayaran
            }

            try {
                this.loading = true
                await this.$store.dispatch('penjualan/bayar', [payload])
                this.loading = false

                this.displaySuccess({
                    message: 'Pembayaran berhasil'
                })

                setTimeout(async () => {
                    await this.$store.dispatch('penjualan/getData', {
                        id_sales: this.user.karyawan.id
                    })
                    this.$router.push(`/penjualan/detail/${this.$route.params.id}`)
                }, 1000)
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        }
    },  
    async created() {
        this.penjualan = this.$store.getters['penjualan/search'](this.$route.params.id)
        if(!this.penjualan) {
            if(this.$route.params.id) { // if action detail
                // this.$router.push(`/penjualan/detail/${this.$route.params.id}`)
            }
            else {
                // this.$router.push('/penjualan')
            }
        }
        this.getKonsumenInfo()
    }
}
</script>